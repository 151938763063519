.offcanvas{
    text-align: right;
    background-color: $offcanvas-bg;
    height: 80vh;
}
.offcanvas-item-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.offcanvas-item{
    color: $turquoise;
}
.offcanvas-logout{
    color: $offcanvas-logout;
}