$scotcher-height: 90px !default;
#scotcher{
    width: 100%;
    height: $scotcher-height;
    position: fixed;
    bottom:0;

    svg {
        width: 100%;
        position: absolute;
        height: 66px;
        bottom:0;
        z-index: 500;
    }
}
.scotcher-toucher{
    position: absolute;
    bottom:0;
    width: 100%;
    // height: 60px;
    z-index: 501;
    display:flex;
    justify-content: space-between;
    align-items:flex-end;
}
#btn-addcontract, #btn-hamburger, #btn-dashboard{
    width: 60px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    cursor:pointer;
}
#btn-addcontract, #btn-hamburger{
    line-height: 45px;
    font-size: 60px;

}
#btn-dashboard{
    width: 60px;
    height: 60px;
    border-radius:30px;
    background-color: $turquoise;
    margin-bottom: 10px;
    line-height: 60px;
    font-size: 30px;
}
