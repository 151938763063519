
// Body
$body-bg:            #FFFFFF;

// Colors
$white:              #FFFFFF;
$white-four-fifth:   rgba(255,255,255,0.8);
$black:              #000000;
$turquoise:          #00B9BD;
$turquoise-mid:      rgba(0,185,189,0.5);
$turquoise-light-light:     #E5F8F8;
$font-default-color: #000000;
$grey:                  #63747F;

$red:                #FF4343;
$red-alt:               #FF5E5E;

//Buttons
$btn-default-bg:     $turquoise;
$btn-default-color:  $white;
$btn-default-border: $turquoise;

$btn-invert-bg:      $white;
$btn-invert-color:   $turquoise;
$btn-invert-border:  $turquoise;

// Forms
$input-default-bg:      $white;
$input-default-color:   $black;
$input-default-label-color: $black;
$input-default-border:  $turquoise;
$input-default-shadow:  #CACACA;

$input-error-border:    $red;
$input-error-label-color: $red;

// OffCanvas
$offcanvas-bg:      $white-four-fifth;
$offcanvas-logout:  $red-alt;

// List
$list-bg-even:      $turquoise-light-light;
$dotted-bottom:     $grey;