
@import "partials/reset";
@include global-reset;
*, *:before, *:after{
    box-sizing: border-box;
}
input, button, select, text-area{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color:inherit;
    margin: 0;
}

@import "neovariables";

@import "partials/typography";
@import "partials/block";

// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "helpers";
@import "components/logo";
@import "components/button";
@import "components/forms";
@import "components/stepper";
@import "components/scotcher";
@import "components/icons";
@import "components/widget";
@import "components/offcanvas";
@import "components/list";
@import "components/avatar";

html, body{
    height:100%;
}
body{
    background-color: $body-bg;

}
#app{
    &:before, &:after{
        content: " ";
        display: table;
    }
    // margin-bottom: $scotcher-height;
    height: 100%;
}
.container{
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    &:before, &:after{
        content: " ";
        display: table;
    }
}

@import "views/welcome";
@import "views/styleguide";

