label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    .has-error & {
        color: $input-error-label-color;
    }
}
.form-group {
    margin-bottom: 15px;
}
.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    .has-error & {
        color: $input-error-label-color;
    }
}
input[type="text"] {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.5;
    color: $input-default-color;
    background-color: $input-default-bg;
    border: 1px solid $input-default-border;
    border-radius: 7.2px;


    box-shadow: 0 1px 4px 0 $input-default-shadow;

    // box-shadow: inset 0 1px 1px rgba(0, 185, 189, 0.705);
    &:focus{
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 6px rgba(0, 185, 189, 0.6);
    }
    .has-error &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 6px rgba(255, 67, 67, 0.6);
    }


    .has-error & {
        border: 1px solid $input-error-border;
    }
}
