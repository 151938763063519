$list-small-item-height: 35px;
.list-small{
}
.list-small-item{
    height:$list-small-item-height;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    &:nth-child(odd) {

    }
    &:nth-child(event) {

    }
}
.list-small-item-thumb{
    flex: 0 0 20%;
}
.list-small-item-content{
    padding: 0 3px;
    flex: 0 0 78%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-small-item:nth-child(even) & {
        background-color: $list-bg-even;
    }
}
.list-small-item-thumb{
    background-color: #CCC;
    border-radius: 6px;
    position: relative;
    &[data-convention="proximus"] {
        background-color: #AAA;
    }
    &:before{
        content:'';
        display: block;
        position: absolute;
        top:0;
        left:0;
        width: 18px;
        height: 10px;
        border-radius: 3px;
        background-color: #000;
        margin-left: -3px;
        margin-top: -3px;
    }
    &[data-status="warning"] {
        &:before{
            background-color: $red-alt;
        }
    }

}
.list-bloc{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.list-bloc-item{
    background-color: $turquoise;
    padding:4px;
    margin:4px;
    text-align: center;
}
.list-bloc-label{
    color: $white;
}
.list-product{
}
.list-product-item{
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}
.list-product-item-label{
    flex: 1 0 70%;
    border-bottom: 1px solid $turquoise;
}
.list-product-item-action{
    flex: 0 0 30%;
    color: $turquoise;
    overflow: hidden;
}
.list-product-item-action-value{
    border:1px solid $turquoise;
    display: inline-block;
    padding: 3px;
}
.list-regular{
}
.list-regular-item{
    display: flex;
    align-items: center;
    border-bottom: 1px dashed $dotted-bottom;
    height: 55px;
}
.list-regular-item-thumb{
    flex: 0 0 20%;
}
.list-regular-item-desc{
    color:$black;
}
.list-big{
}
.list-big-item{
    display: flex;
    align-items: flex-end;
}
.list-big-item-img{
    flex: 0 0 25%;
    overflow: hidden;
    img{
        display: block;
    }
}
.list-big-item-txt{
    
}
.list-big-item-txt-head{
    display: flex;
    justify-content: space-between;
}
.list-big-item-txt-head-title{
    color: $turquoise;
}
.list-big-item-txt-head-date{
    color:$grey;
}
.list-big-item-txt-content{
    border-bottom: 1px solid $turquoise;
}