.btn {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 2px solid $btn-default-border;
    border-radius: 7.2px;
    white-space: nowrap;
    padding: 0.5rem 2.6rem;
    user-select: none;
    background: $btn-default-bg;
    color:$btn-default-color;
    &.btn-invert {
        border-color: $btn-invert-border;
        background: $btn-invert-bg;
        color:$btn-invert-color;
    }
}
.btn-small{
    padding: 0.3rem 1.3rem;
}
.btn-tiny{
    padding: 0.15rem 0.8rem;
}

.btn-question{
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    border: 3px solid $turquoise;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: block;
    overflow: hidden;
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    line-height: 25px;
    color: $turquoise;
}
