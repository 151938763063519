.welcome{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:stretch;
    .app-logo, .app-link{
        // flex-basis:50%;
    }
    .app-logo{
        padding-top: 1.8rem;
    }
    .app-link{
        padding: 28px 20px 80px;
    }
    .btn {
        display: block;
        width:60%;
        margin: 0 auto 10px;
    }
    p{
        background-color: rgba(255,255,255,0.7);
        border-radius: 5px;
        padding: 15px;
        width: 60%;
        margin: 10px auto 0;
        color:#000;
    }
}
.home{
    background: url(/storage/mobile-bg.jpg) no-repeat;
    background-size: cover;
}
