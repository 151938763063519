.widget{
    width:100%;
    max-width:400px;
    border: 1px solid $turquoise;
    border-radius: 3px;
    margin-bottom: 0.6rem;
    padding: 4px 0;
}
.widget-col-wrapper{
    display:flex;
}
.widget-col:first-child{
        flex-basis: 75%;
    }
.widget-col:last-child{
    flex-basis: 25%;
}
#widget-wallet{
    padding-left: 5px;
    background-color: $turquoise;
    
    .widget-action{
        height:100%;
        a{
            display:flex;
            align-items:center;
            justify-content: flex-end;
            height:100%
        }
        svg{
            margin-right:-20px;
        }
    }
    .widget-stat{
        display:flex;
        justify-content:space-between;
        border:1px solid $white;
        border-radius: 2px;
        padding:2px 5px;
        h4{
            border-bottom: 1px solid $white;
        }
        div{
            text-align: center;
            padding: 3px 2px;
        }
    }
}
$progress-bar-wrapper-height: 30px;
.progress-bar-wrapper{
    display: flex;
    border-bottom: 1px solid $turquoise;
    padding: 0 5px;
    height: $progress-bar-wrapper-height;
    &:last-child{
        border-bottom: none;
    }
}
.progress-bar-col{
    flex:1;
}
.progress-bar-text{
    text-align: right;
    width:20%;
    line-height: $progress-bar-wrapper-height;
}
.progress-bar{
    margin:2px 0;
    border-radius: 3px;
    height: 5px;
    background-color: $turquoise-mid;
    .progress-bar-current{
        border-radius: 3px;
        float:left;
        height: 100%;
        background-color: $turquoise;
    }
}
#widget-contrat{
    display: flex;
    flex-wrap: wrap;
    .widget-stat{
        // width:100%;
        flex:1 0 100%;

    }
}
.historybydaybymonth-wrapper{
    display: flex;
    flex-wrap: nowrap;
    // height: 30px;
    align-items: center;
    padding: 0 5px 4px;
    border-bottom: 1px solid $turquoise;
    &:last-child{
        border-bottom: none;
    }
}
.historybydaybymonth-vert-bar{
    height: 20px;
    flex: 1 0 auto;
    padding: 0 8px;
    ul{
        display: flex;
        align-items: flex-end;
        height: 100%;
        justify-content: space-between;
    }
    li{
        background-color: $turquoise;
        width: 3px;
    }
}