.avatar-wrapper{
    background-image: url(/storage/avatar/gold-level.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding:5px;
}
.avatar-inner{
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    img{
        display: block;
        width: 100%;
    }
}