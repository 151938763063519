// Fonts
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,600|Open+Sans:300,400,600');

@font-face {
    font-family: 'Datalegreya-Thin';
    src: url('fonts/datalegraya/Datalegreya-Thin.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Datalegreya-Gradient';
    src: url(/storage/fonts/datalegreya/datalegreya-gradient.otf);
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Datalegreya-Dot';
    src: url('fonts/datalegraya/Datalegreya-Dot.otf');
    font-weight: normal;
    font-style: normal;
}

body{
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: $font-default-color;
}
a{
    text-decoration:none;
}

.btn{
    font-family: "Open sans", sans-serif;

}

h1, h2, h3, h4, h5, h6{
    font-family: inherit;
    line-height: 1.1;
    color: inherit;
}

// Size
h1 {
    font-size: 48px;
}
h2 {
    font-size: 40px;
    line-height: 1.8;
}
h3 {
    font-size: 32px;
}
h4{
    font-size: 24px;
}
h5{
    font-size: 20px;
}

.btn{
    font-size: 1em;
    line-height: 1.6;
    font-weight: normal;
}
.btn-small{
    font-size: 0.85em;
    line-height: 1.4;
}
.btn-tiny{
    font-size: 0.7em;
    line-height: 1.2;
}
.btn-stepper{
    font-size: 0.8em;
}

// Margin
h1, h2, h3, h4, h5, h6{
    margin-left:5px;
    margin-right: 5px;
}
h1, h2, h3{
    margin-bottom: 10px;
    margin-top: 20px;
}
h4, h5, h6{
    margin-bottom: 5px;
    margin-top: 10px;
}
// Special
h2{
    border-bottom: 2px solid $turquoise;
}

p{
    margin-bottom: 1rem;
}

.widget {
    h3{
        -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
        -moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
        -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
        -ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
        font-feature-settings: "kern" on, "liga" on, "calt" on;
        font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: "Datalegreya-Gradient", sans-serif;
        margin: 0;
        font-size: 60px;
    }
    h4{
        margin: 0;
        font-weight:300;
        font-size: 16px;
        margin-bottom: 4px;
    }
}
.widget-stat-value{
    font-family: "Open sans", sans-serif;
    font-weight:600;
    font-size: 20px;
    color: rgba(255,255,255,0.89);
}
#widget-wallet{
    color:$white;
}
.progress-bar-text{
    font-family: "Open sans", sans-serif;
    font-size: 15px;
}
.offcanvas-item{
    font-weight: 600;
    font-size: 2rem;
}
.list-bloc-label{
    font-family: "Open sans", sans-serif;
    font-size: 20px;
}
.list-product-item-label{
    font-family: "Open sans", sans-serif;
    font-size: 1rem;
}
.list-product-item-action{
    font-family: "Open sans", sans-serif;
    font-size: 1.4rem;
}
.list-regular-item-desc{
    font-family: "Open sans", sans-serif;
    font-size: 10px;
    line-height: 14px;
}
.list-big-item-txt-head-title{
    font-size: 20px;
}
.list-big-item-txt-head-date{
    font-family: "Open sans", sans-serif;
    font-size: 10px;
}
.list-big-item-txt-content{
    font-family: "Open sans", sans-serif;
    font-size: 11px;
    line-height: 16px;
}