@import url("https://fonts.googleapis.com/css?family=Dosis:300,400,600|Open+Sans:300,400,600");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

input, button, select, text-area {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
}

@font-face {
  font-family: 'Datalegreya-Thin';
  src: url("fonts/datalegraya/Datalegreya-Thin.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Datalegreya-Gradient';
  src: url(/storage/fonts/datalegreya/datalegreya-gradient.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Datalegreya-Dot';
  src: url("fonts/datalegraya/Datalegreya-Dot.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

a {
  text-decoration: none;
}

.btn {
  font-family: "Open sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
  line-height: 1.8;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

.btn {
  font-size: 1em;
  line-height: 1.6;
  font-weight: normal;
}

.btn-small {
  font-size: 0.85em;
  line-height: 1.4;
}

.btn-tiny {
  font-size: 0.7em;
  line-height: 1.2;
}

.btn-stepper {
  font-size: 0.8em;
}

h1, h2, h3, h4, h5, h6 {
  margin-left: 5px;
  margin-right: 5px;
}

h1, h2, h3 {
  margin-bottom: 10px;
  margin-top: 20px;
}

h4, h5, h6 {
  margin-bottom: 5px;
  margin-top: 10px;
}

h2 {
  border-bottom: 2px solid #00B9BD;
}

p {
  margin-bottom: 1rem;
}

.widget h3 {
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
  font-feature-settings: "kern" on, "liga" on, "calt" on;
  font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Datalegreya-Gradient", sans-serif;
  margin: 0;
  font-size: 60px;
}

.widget h4 {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 4px;
}

.widget-stat-value {
  font-family: "Open sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.89);
}

#widget-wallet {
  color: #FFFFFF;
}

.progress-bar-text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
}

.offcanvas-item {
  font-weight: 600;
  font-size: 2rem;
}

.list-bloc-label {
  font-family: "Open sans", sans-serif;
  font-size: 20px;
}

.list-product-item-label {
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
}

.list-product-item-action {
  font-family: "Open sans", sans-serif;
  font-size: 1.4rem;
}

.list-regular-item-desc {
  font-family: "Open sans", sans-serif;
  font-size: 10px;
  line-height: 14px;
}

.list-big-item-txt-head-title {
  font-size: 20px;
}

.list-big-item-txt-head-date {
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}

.list-big-item-txt-content {
  font-family: "Open sans", sans-serif;
  font-size: 11px;
  line-height: 16px;
}

section {
  padding: 5px;
}

.helpers-col-spacebottom {
  margin-bottom: 15px;
}

.logotitle a {
  display: block;
}

.logotitle img {
  display: block;
  margin: 0 auto;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 2px solid #00B9BD;
  border-radius: 7.2px;
  white-space: nowrap;
  padding: 0.5rem 2.6rem;
  user-select: none;
  background: #00B9BD;
  color: #FFFFFF;
}

.btn.btn-invert {
  border-color: #00B9BD;
  background: #FFFFFF;
  color: #00B9BD;
}

.btn-small {
  padding: 0.3rem 1.3rem;
}

.btn-tiny {
  padding: 0.15rem 0.8rem;
}

.btn-question {
  position: absolute;
  top: 0.4em;
  right: 0.4em;
  border: 3px solid #00B9BD;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: block;
  overflow: hidden;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 25px;
  color: #00B9BD;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.has-error label {
  color: #FF4343;
}

.form-group {
  margin-bottom: 15px;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.has-error .help-block {
  color: #FF4343;
}

input[type="text"] {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 1.5;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #00B9BD;
  border-radius: 7.2px;
  box-shadow: 0 1px 4px 0 #CACACA;
}

input[type="text"]:focus {
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(0, 185, 189, 0.6);
}

.has-error input[type="text"]:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(255, 67, 67, 0.6);
}

.has-error input[type="text"] {
  border: 1px solid #FF4343;
}

.step-indicicator .step-bullet {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 185, 189, 0.5);
  margin: 0 0.375rem;
}

.step-indicicator .step-bullet.current {
  background-color: #00B9BD;
}

.step-indicicator .step-bullet.current:after {
  content: '';
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.25rem auto 0;
  border-radius: 0.25rem;
  background-color: #FFFFFF;
}

.step-indicicator .step-bullet.reached {
  background-color: #00B9BD;
}

/**
 * stepper-group-action">
            <button class="btn btn-stepper btn-stepper-left btn-stepper-alone
 */
.stepper-group-action {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.btn-stepper {
  width: 140px;
}

.btn-stepper-left {
  padding-left: 0.9rem;
}

.btn-stepper-left:not(.btn-stepper-alone) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-stepper-left:before {
  content: '<';
  padding-right: 1.3rem;
}

.btn-stepper-right {
  padding-right: 0.9rem;
}

.btn-stepper-right:not(.btn-stepper-alone) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-stepper-right:after {
  content: '>';
  padding-left: 1.3rem;
}

.btn-stepper-left:before, .btn-stepper-right:after {
  font-weight: 300;
  vertical-align: middle;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  font-size: 30px;
  color: #FFFFFF;
}

#scotcher {
  width: 100%;
  height: 90px;
  position: fixed;
  bottom: 0;
}

#scotcher svg {
  width: 100%;
  position: absolute;
  height: 66px;
  bottom: 0;
  z-index: 500;
}

.scotcher-toucher {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 501;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#btn-addcontract, #btn-hamburger, #btn-dashboard {
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

#btn-addcontract, #btn-hamburger {
  line-height: 45px;
  font-size: 60px;
}

#btn-dashboard {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #00B9BD;
  margin-bottom: 10px;
  line-height: 60px;
  font-size: 30px;
}

.svgicon {
  background: #00B9BD;
  display: inline-block;
  height: 26px;
  width: 26px;
  padding: 2px;
  border-radius: 4px;
}

.widget {
  width: 100%;
  max-width: 400px;
  border: 1px solid #00B9BD;
  border-radius: 3px;
  margin-bottom: 0.6rem;
  padding: 4px 0;
}

.widget-col-wrapper {
  display: flex;
}

.widget-col:first-child {
  flex-basis: 75%;
}

.widget-col:last-child {
  flex-basis: 25%;
}

#widget-wallet {
  padding-left: 5px;
  background-color: #00B9BD;
}

#widget-wallet .widget-action {
  height: 100%;
}

#widget-wallet .widget-action a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

#widget-wallet .widget-action svg {
  margin-right: -20px;
}

#widget-wallet .widget-stat {
  display: flex;
  justify-content: space-between;
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  padding: 2px 5px;
}

#widget-wallet .widget-stat h4 {
  border-bottom: 1px solid #FFFFFF;
}

#widget-wallet .widget-stat div {
  text-align: center;
  padding: 3px 2px;
}

.progress-bar-wrapper {
  display: flex;
  border-bottom: 1px solid #00B9BD;
  padding: 0 5px;
  height: 30px;
}

.progress-bar-wrapper:last-child {
  border-bottom: none;
}

.progress-bar-col {
  flex: 1;
}

.progress-bar-text {
  text-align: right;
  width: 20%;
  line-height: 30px;
}

.progress-bar {
  margin: 2px 0;
  border-radius: 3px;
  height: 5px;
  background-color: rgba(0, 185, 189, 0.5);
}

.progress-bar .progress-bar-current {
  border-radius: 3px;
  float: left;
  height: 100%;
  background-color: #00B9BD;
}

#widget-contrat {
  display: flex;
  flex-wrap: wrap;
}

#widget-contrat .widget-stat {
  flex: 1 0 100%;
}

.historybydaybymonth-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 5px 4px;
  border-bottom: 1px solid #00B9BD;
}

.historybydaybymonth-wrapper:last-child {
  border-bottom: none;
}

.historybydaybymonth-vert-bar {
  height: 20px;
  flex: 1 0 auto;
  padding: 0 8px;
}

.historybydaybymonth-vert-bar ul {
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
}

.historybydaybymonth-vert-bar li {
  background-color: #00B9BD;
  width: 3px;
}

.offcanvas {
  text-align: right;
  background-color: rgba(255, 255, 255, 0.8);
  height: 80vh;
}

.offcanvas-item-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offcanvas-item {
  color: #00B9BD;
}

.offcanvas-logout {
  color: #FF5E5E;
}

.list-small-item {
  height: 35px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.list-small-item-thumb {
  flex: 0 0 20%;
}

.list-small-item-content {
  padding: 0 3px;
  flex: 0 0 78%;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-small-item:nth-child(even) .list-small-item-content {
  background-color: #E5F8F8;
}

.list-small-item-thumb {
  background-color: #CCC;
  border-radius: 6px;
  position: relative;
}

.list-small-item-thumb[data-convention="proximus"] {
  background-color: #AAA;
}

.list-small-item-thumb:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 10px;
  border-radius: 3px;
  background-color: #000;
  margin-left: -3px;
  margin-top: -3px;
}

.list-small-item-thumb[data-status="warning"]:before {
  background-color: #FF5E5E;
}

.list-bloc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.list-bloc-item {
  background-color: #00B9BD;
  padding: 4px;
  margin: 4px;
  text-align: center;
}

.list-bloc-label {
  color: #FFFFFF;
}

.list-product-item {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.list-product-item-label {
  flex: 1 0 70%;
  border-bottom: 1px solid #00B9BD;
}

.list-product-item-action {
  flex: 0 0 30%;
  color: #00B9BD;
  overflow: hidden;
}

.list-product-item-action-value {
  border: 1px solid #00B9BD;
  display: inline-block;
  padding: 3px;
}

.list-regular-item {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #63747F;
  height: 55px;
}

.list-regular-item-thumb {
  flex: 0 0 20%;
}

.list-regular-item-desc {
  color: #000000;
}

.list-big-item {
  display: flex;
  align-items: flex-end;
}

.list-big-item-img {
  flex: 0 0 25%;
  overflow: hidden;
}

.list-big-item-img img {
  display: block;
}

.list-big-item-txt-head {
  display: flex;
  justify-content: space-between;
}

.list-big-item-txt-head-title {
  color: #00B9BD;
}

.list-big-item-txt-head-date {
  color: #63747F;
}

.list-big-item-txt-content {
  border-bottom: 1px solid #00B9BD;
}

.avatar-wrapper {
  background-image: url(/storage/avatar/gold-level.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px;
}

.avatar-inner {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar-inner img {
  display: block;
  width: 100%;
}

html, body {
  height: 100%;
}

body {
  background-color: #FFFFFF;
}

#app {
  height: 100%;
}

#app:before, #app:after {
  content: " ";
  display: table;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.welcome {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.welcome .app-logo {
  padding-top: 1.8rem;
}

.welcome .app-link {
  padding: 28px 20px 80px;
}

.welcome .btn {
  display: block;
  width: 60%;
  margin: 0 auto 10px;
}

.welcome p {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 15px;
  width: 60%;
  margin: 10px auto 0;
  color: #000;
}

.home {
  background: url(/storage/mobile-bg.jpg) no-repeat;
  background-size: cover;
}
