.step-indicicator{
    .step-bullet{
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: $turquoise-mid;
        margin: 0 0.375rem;
        &.current{
            background-color: $turquoise;
            &:after{
                content: '';
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                margin: 0.25rem auto 0;
                border-radius: 0.25rem;
                background-color: $white;
            }
        }
        &.reached{
            background-color: $turquoise;
        }
    }
}
/**
 * stepper-group-action">
            <button class="btn btn-stepper btn-stepper-left btn-stepper-alone
 */
.stepper-group-action{
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}
$pseudo-padding: 1.3rem;
$stepper-button-padding: 0.9rem;
.btn-stepper{
    width: 140px;
}
.btn-stepper-left{
    padding-left: $stepper-button-padding;
    &:not(.btn-stepper-alone) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    &:before{
        content:'<';
        padding-right: $pseudo-padding;
    }
}
.btn-stepper-right{
    padding-right: $stepper-button-padding;
    &:not(.btn-stepper-alone){
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &:after{
        content:'>';
        padding-left: $pseudo-padding;
    }
}
.btn-stepper-left, .btn-stepper-right{
}
.btn-stepper-left:before, .btn-stepper-right:after{
    font-weight:300;
    vertical-align: middle;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    font-size: 30px;
    color:$white;
}